/*
 * @Author: lbh
 * @Date: 2021-04-22 15:39:05
 * @LastEditors: lzh
 * @LastEditTime: 2022-03-11 16:13:09
 * @Description: 工具類
 */
let util = {
  // 獲取頁面寬度
  W() {
    let clientWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth; // 窗口寬度
    let clientHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight; // 窗口寬度
    return { w: clientWidth, h: clientHeight };
  },

  /**
   * 獲取 樣式
   * @param {元素節點/類名} ele
   * @param {具體屬性} style
   * @returns
   */
  getStyle(ele, style) {
    try {
      if (typeof ele == 'object') {
        let p = ele.style[style];
        if (!p) p = getComputedStyle(ele)[style];
        return p;
      } else {
        let dom = document.querySelector(ele);
        let p = dom.style[style];
        if (!p) p = getComputedStyle(dom)[style];
        return p;
      }
    } catch (error) {
      console.log(error);
      return '';
    }
  },
  // 拼接網絡圖片路徑
  getImgPath(path) {
    return `https://ricepon.oss-cn-hongkong.aliyuncs.com/home/${path}`;
  },
  // 設置 網頁title
  setTitle(title) {
    document.title = title;
    // 頁面滾動到頂部
    window.scrollTo({ top: 0 });
  },
  // 判斷是否是手機
  isMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag ? true : false;
  },
  // 是不是 ricepon 域名
  isRicepon() {
    if (location.href.indexOf('hktest.ricepon') > -1) {
      return location.href.indexOf('ricepon-main') > -1;
    } else {
      if (location.href.indexOf('www.ricepon') > -1) {
        return location.href.indexOf('www.ricepon') > -1;
      } else {
        return location.href.indexOf('ricepon-main') > -1;
      }
    }
  },
  // 正則校驗
  regexTest: {
    hk(phone) {
      const pattern = /^(5|6|8|9)\d{7}$/;
      return pattern.test(phone);
    },
  },
};
export default util;
