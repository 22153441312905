/*
 * @Author: lbh
 * @Date: 2021-04-29 15:10:36
 * @LastEditors: lbh
 * @LastEditTime: 2021-10-10 10:05:13
 * @Description: 全部接口
 */
import axios from '@/js/utils/http';

let host = location.origin;
if (host.indexOf('www.') > -1) {
  host = 'https://m.ricepon.com';
}

let riceponSystem = host + '/ricepon-system/api';

// 添加 免費申請 記錄
export const addHomeUser = (params) =>
  axios.post(riceponSystem + '/public/home/addHomeUser', params);
