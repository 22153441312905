/*
 * @Author: lbh
 * @Date: 2020-12-24 18:12:01
 * @LastEditors: lbh
 * @LastEditTime: 2021-07-03 18:35:43
 * @Description: axios 二次封裝
 */
// 導入 vue 框架
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';

Vue.prototype.axios = axios;

// 請求攔截
axios.interceptors.request.use(
  (config) => {
    // debugger
    if (config.url.indexOf('addHomeUser') > -1) {
      config.headers['Content-type'] = 'application/json; charset=UTF-8';
    } else {
      if (config.method == 'post') config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 響應 攔截
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

var https = {
  /**
   * 獲取 頭部信息
   */
  setHeaders() {
    var headers = {
      'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      apptype: 13, // 新接口
      version: 'main', // 表示是 新h5
    };
    return headers;
  },
  /**
   * get 請求
   * @param {string} url
   * @param {object} params
   */
  get(url, params) {
    console.log(url);
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: this.setHeaders(),
          params: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * post 請求
   * @param {string} url
   * @param {object} params
   */
  post(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: this.setHeaders(),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
};
export default https;
