/*
 * @Author: lbh
 * @Date: 2022-03-24 15:13:28
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-24 15:15:05
 * @Description: file content
 */
import selfContact from './commo/contact.vue';

const components = [selfContact];

// Vue.use 必须保证注册的组件有 install 函数
const install = (vue) => {
  components.map((component) => {
    // 注意这里的 component.name 是每个组件中export default自定义的 name，这个 name 可以在项目vue组件中直接使用
    vue.component(component.name, component);
  });
};

// 注：导出，供 Vue.use注册，其实 Veu.use()是调用Vue.js中的install函数，然后将我们暴露的install执行，真正的注册还是 Vue.component()
// 第三方库基本以这种方式注册他们的各个组件，直接使用 Vue.use(element)，其内部已经有 instatll => Vue.component()
export default {
  install, // install 必须暴露，供vue去调用，检测并注册组件
  selfContact,
};
