/*
 * @Author: lbh
 * @Date: 2021-04-21 16:18:20
 * @LastEditors: lbh
 * @LastEditTime: 2022-06-21 17:43:50
 * @Description: 頁面滾動處理
 */
import jquery from 'jquery';
import util from './util';
let scroll = () => {
  let top = 0, // 記錄當前滾動距離
    f_up = false, // 動畫是否執行
    f_down = false; // 動畫是否執行
  //  header 的動畫
  let headerScroll = () => {
    let scr =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滾動的距離
    let clientHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight; // 窗口高度
    let scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight; // 滚动视口高度(也就是当前元素的真实高度)
    if (scr > top) {
      f_up = false;
      if (!f_down) {
        jquery('.self-header .header_1').stop();
        f_down = true;
        jquery('.self-header .header_1').animate(
          {
            top: `-125px`,
          },
          {
            speed: 500,
            callback: () => {
              f_down = false;
            },
          }
        );
      }
    } else {
      f_down = false;
      if (!f_up) {
        jquery('.self-header .header_1').stop();
        f_up = true;
        jquery('.self-header .header_1').animate(
          {
            top: `0`,
          },
          {
            speed: 500,
            callback: () => {
              f_up = false;
            },
          }
        );
      }
    }
    top = scr;
    if (clientHeight + scr == scrollHeight) {
      f_down = false;
      if (!f_up) {
        jquery('.self-header .header_1').stop();
        f_up = true;
        jquery('.self-header .header_1').animate(
          {
            top: `0`,
          },
          {
            speed: 500,
            callback: () => {
              f_up = false;
            },
          }
        );
      }
    }
  };
  // 背景圖滾動
  let backgroundScroll = () => {
    let back = document.querySelector('.background-box .img-box');
    if (back != null) {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滾動的距離
      back.style.transform = `translate3d(0px, ${top / 5}px, 0px)`;
    }
  };

  let isRicepon = () => {
    if (location.href.indexOf('hktest.ricepon') > -1) {
      return location.href.indexOf('ricepon-main') > -1;
    } else {
      if (location.href.indexOf('www.ricepon') > -1) {
        return location.href.indexOf('www.ricepon') > -1;
      } else {
        return location.href.indexOf('ricepon-main') > -1;
      }
    }
  };

  let createTop = () => {
    let _ = document.querySelector('.is-go-top');
    if (!_) {
      let topBox = document.createElement('div');
      topBox.className = 'is-go-top';
      let top = document.createElement('div');
      top.textContent = '回到頂部';
      top.style.position = 'fixed';
      top.style.right = '24px';
      top.style.bottom = '120px';
      top.style.boxShadow = '0 2px 6px #ccc';
      top.style.width = '160px';
      top.style.height = '70px';
      top.style.zIndex = '10';
      top.style.borderRadius = '35px';
      top.style.display = 'flex';
      top.style.alignItems = 'center';
      top.style.justifyContent = 'center';
      top.style.fontSize = '24px';
      top.style.userSelect = 'none';
      top.style.backgroundColor = '#FFF';
      top.className = 'isTop';
      top.onclick = () => {
        window.scrollTo(0, 0);
      };

      let topMin = document.createElement('img');
      topMin.src = require('../../assets/default/TOP.png');
      topMin.style.width = '60px';
      topMin.style.height = '60px';
      topMin.style.position = 'fixed';
      topMin.style.right = '24px';
      topMin.style.bottom = '120px';
      topMin.style.display = 'none';
      topMin.className = 'isTopMin';
      topMin.onclick = () => {
        window.scrollTo(0, 0);
      };
      topBox.append(top);
      topBox.append(topMin);
      document.querySelector('.t-body').append(topBox);
    }
  };

  // 窗體滾動
  window.onscroll = function (e) {
    if (!util.isMobile()) {
      headerScroll();
      backgroundScroll();
    }
    if (!isRicepon()) {
      if (window.scrollY > 500) {
        createTop();
      } else {
        let _ = document.querySelector('.is-go-top');
        if (_) _.remove();
      }
    }
  };
};

export default scroll;
